import React from 'react';
import config from '../../config';

import tadvisor from '../assets/img/tripadvisor.svg';
import facebook from '../assets/img/facebook.svg';

export default function SocialLinks() {
  return (
    <section className="contact-section bg-black ">
      <div className="container ">
        <div className="row">
          <div className="col">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="fas fa-envelope text-primary mb-2"></i>
                <h4 className="text-uppercase m-0">Email</h4>
                <hr className="my-4" />
                <div className="small text-black-50">
                  <a href={`mailto:${config.email}`}>{config.email}</a>
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="fas fa-mobile-alt text-primary mb-2"></i>
                <h4 className="text-uppercase m-0">Téléphone</h4>
                <hr className="my-4" />
                <div className="small text-black-50">
                  <a href='tel:{config.phone}'>{config.phone}</a>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div className="social d-flex justify-content-center">
          <a key={config.tadvisorUrl} href={config.tadvisorUrl}>
                <img  width={40} src={`${tadvisor}`} alt="trip advisor logo"/>
          </a>
          <a key={config.facebookUrl} href={config.facebookUrl}>
                <img  width={40} src={`${facebook}`} alt="facebook logo"/>
          </a>
          
        </div>
      </div>
    </section>
  );
}
