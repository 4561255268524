import React from 'react';
import config from '../../config';
import logo from '../assets/images/logo.jpg';

export default function Subscribe() {
  return (
    <section id="signup" className="signup-section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">
          <a href={config.externalSite}>
              <img className="img-fluid" src={logo} alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
