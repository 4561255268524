import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import demoImage1 from '../assets/images/demo-image-01.jpg';
import demoImage2 from '../assets/images/demo-image-02.jpg';

const IndexPage = () => (
  <Layout>
    <Header />
    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
          <Scroll type="id" element="projects">
            <button className="btn btn-primary" href='#'>
              Le canyon marin, c'est quoi ?
            </button>
          </Scroll>
        </div>
      </div>
    </header>

    <section id="projects" className="projects-section bg-light">
      <div className="container">
        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage1} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h6 className="mb-0 text-white-50">Le canyoning marin, c’est quoi ?</h6>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                  <br></br>
                  <p className="mb-0 text-white-50">
                  Du canyoning en mer, avec les mêmes sensations qu’en rivière.
Vous allez pouvoir faire de la tyrolienne (60m), des sauts, de la slack-line (sangle tendue au-dessus du vide), du rappel et même un peu de snorkeling.
Notre activité coasteering (canyoning marin) se déroule dans une calanque.

                  </p>
                 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage2} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                
                  <p className="mb-0 text-white-50">
                  La marche d’approche est de 15 minutes par un petit sentier avec une magnifique vue, aussi vous pourrez rapidement vous émerveiller de la beauté du lieu.
Cette calanque est un endroit sauvage, où seuls quelques pêcheurs se rendent à l’automne ou au printemps.
Une fois arrivés sur le site du canyon marin, le guide vous fera un briefing afin de vous sensibiliser au lieu et à la sécurité.

                      </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <h4>Plus d'informations sur notre site : <a href={config.externalSite}>https://canyoningbalagne.fr/</a></h4>
            </div>
          </div>  
         
        </div>
      </div>
    </section>

    <Subscribe />

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
